import React from 'react';
import { ScrollProvider } from './src/utils/hooks/use-scroll';
import { CartProvider } from 'react-use-cart';
import { Script } from 'gatsby';
// TODO: How to inject third party scripts
// TODO: use PartyTown for this: https://www.gatsbyjs.com/blog/how-to-add-google-analytics-gtag-to-gatsby-using-partytown/#gatsby-skip-here
export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Script
        id="cookies"
        src="//cdn.cookie-script.com/s/7eb08251dc41ddd3b8f4b48796e77f90.js"
      />
      <CartProvider>
        <ScrollProvider>{element}</ScrollProvider>
      </CartProvider>
    </>
  );
};
