module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-use-query-params@1.0.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-d_7q7eqn6f7psuwucdnovhzudg2a/node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@1_z6fs4wki575vsyrfh4eaehilgy/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-JH0XZEX8W6"],"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":200},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__5ew7aqhtbcntmgy4quedt5ipai/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
